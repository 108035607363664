export const EDITOR_PROPERTIES_REGEXP = /{([\S]+?)}/g

export const FIND_PROPERTIES_IN_TEXT_REGEX = /(\{.*?\})/g

export const CHECK_PROPERTY_STRING_REGEX = /^\{.*?\}/

export const CURLY_BRACES_REGEXP = /[{}]+/g

export const MUSTACHE_TEMPLATE_REGEX = /{{([\s\S]+?)}}/

export const ONLY_CHARACTERS_REGEX = /[^a-zA-Z0-9]/g
